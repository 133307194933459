
import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";

@Component({})
export default class SimpleInputMixin extends Vue {
  @Prop() value: any;
  // @ts-ignore
  localValue = this.value ? this.value : null;

  mounted() {
    if (this.localValue) {
      this.onValueChange();
    }
  }

  @Watch("value")
  private onValueChangeLocal() {
    this.localValue = this.value;
    this.onValueChange();
  }

  input() {
    this.$emit("value", this.localValue);
    this.$emit("input", this.localValue);
    this.$forceUpdate();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onValueChange() {
  }
}

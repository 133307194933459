
import {Component, Vue, Prop, Watch} from "vue-property-decorator";

@Component({
  components: {},
})
export default class FormDialog extends Vue {
  @Prop() value: any;
  @Prop() title!: string | null;
  @Prop() icon!: string;
  @Prop() iconProp!: string | boolean;
  @Prop() label!: string;
  @Prop() color!: string;
  @Prop() dark!: string | boolean;
  @Prop() small!: string | boolean;
  @Prop() medium!: string | boolean;
  @Prop() large!: string | boolean;
  @Prop() isOutlined!: string | boolean;
  @Prop() disabled!: boolean;
  @Prop({default: "600"}) width!: string | number;
  @Prop({default: null}) buttonWidth!: string | number;
  @Prop({default: ""}) buttonHeight!: string | number;
  @Prop() isBigButton!: boolean;
  @Prop() x_large!: boolean;
  @Prop() cover_all!: string | boolean;
  @Prop() iconColor!: string;
  @Prop() big_button_label!: string;
  @Prop() font_color!: string;
  @Prop() height!: string;
  @Prop() big_button_color!: string;
  @Prop({default: true}) isResetLocalValueOnClose!: boolean;
  @Prop() defaultValue!: any;
  @Prop() onlyText!: string | boolean;
  @Prop() tooltip!: string | null;
  @Prop() buttonStyle!: string;

  private buttonColor = this.color ? this.color : "light-blue darken-4";
  private iconStr = this.icon ? this.icon : "mdi-plus-thick";

  show: boolean = false;

  // @ts-ignore
  localValue = Array.isArray(this.value) ? Object.assign([], this.value ? this.value : (this.defaultValue ? JSON.parse(JSON.stringify(this.defaultValue)) : [])) : Object.assign({}, this.value ? this.value : (this.defaultValue ? JSON.parse(JSON.stringify(this.defaultValue)) : {}));

  get isMedium() {
    return this.medium || this.medium === "";
  }

  get isSmall() {
    return this.small || this.small === "";
  }

  get isIcon() {
    return this.iconProp || this.iconProp === "";
  }

  get isDark() {
    return this.dark || this.dark === "";
  }

  get isOnlyText() {
    return this.onlyText || this.onlyText === "";
  }

  @Watch("value")
  onValueChange() {
    if (Array.isArray(this.value)) {
      this.localValue = Object.assign([], this.value ? this.value : []);
    } else {
      this.localValue = Object.assign({}, this.value ? this.value : {});
    }
  }

  onClose() {
    this.show = false;
    if (this.isResetLocalValueOnClose) {
      this.clearLocalValue();
    }
  }

  @Watch('show')
  onVisibilityChange() {
    this.$emit('onVisibilityChange', this.show);
  }

  onInput(val: any) {
    this.localValue = val;
    this.$emit("value", this.localValue);
    this.$emit("input", this.localValue);
  }

  onSuccess(data: any) {
    this.onClose();
    this.$emit("success", data);
  }

  clearLocalValue() {
    if (!this.localValue.id) {
      if (this.defaultValue)
        this.localValue = JSON.parse(JSON.stringify(this.defaultValue));
      else {
        let resetValidation = (component: Vue = this) => {
          component.$children.forEach((child: any) => {
            if (child.$options._componentTag == 'form-wrapper') {
              child.$refs.form.resetValidation();
            }
            if (child.$children.length > 0)
              resetValidation(child);
          });
        }
        resetValidation();
        this.localValue = {};
      }
    }
  }
}
